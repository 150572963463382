<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-02-18 22:57:46
 * @ Description: 司机管理>互联互通客户>互联推送管理详情
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus class="box-card" :labelWidth="120">
        <div slot="header" class="clearfix">
          <span>秘钥定制信息</span>
        </div>
        <ykc-detail-item-plus label="推送名称">
          {{ formData.extName || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="修改人">
          {{ formData.modifiedName || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="修改时间">
          {{ formData.modifiedTime || '——' }}
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-detail-plus class="box-card" :labelWidth="120">
        <div slot="header" class="clearfix">
          <span>流量方/监管平台秘钥</span>
        </div>

        <ykc-detail-item-plus label="流量方名称">
          {{ formData.orgName || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="机构秘玥">
          {{ formData.reqSecretKey || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="消息秘玥">
          {{ formData.reqDataEncryptionKey || '——' }}
        </ykc-detail-item-plus>

        <ykc-detail-item-plus label="初始化向量">
          {{ formData.reqInitializationVector || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="签名秘玥">
          {{ formData.reqSignKey || '——' }}
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-detail-plus class="box-card" :labelWidth="120">
        <div slot="header" class="clearfix">
          <span>运营商秘玥</span>
        </div>
        <ykc-detail-item-plus label="提供方Id">
          {{ formData.operatorId || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="提供方名称">
          {{ formData.operatorName || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="机构秘玥">
          {{ formData.proSecretKey || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="消息秘玥">
          {{ formData.proDataEncryptionKey || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="初始化向量">
          {{ formData.proInitializationVector || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="签名秘玥">
          {{ formData.proSignKey || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="组织机构代码">
          {{ formData.proSocialCreditCode || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="组织机构名称">
          {{ formData.proSocialCreditName || '——' }}
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-detail-plus class="box-card" v-if="formData.stationScopeType">
        <div slot="header" class="clearfix">
          <span>推送电站</span>
        </div>
        <ykc-tree
          v-if="formData.stationScopeType == '2'"
          :data="formData.stationIds"
          selectType="none"
          :props="treeProps"
          :nodeKey="treeNodeKey"></ykc-tree>
        <div v-else>全部电站</div>
      </ykc-detail-plus>
    </div>
  </scroll-layout>
</template>

<script>
  import dayjs from 'dayjs';
  import { cipherUnione, treeUtils, unioneOrg } from '@/service/apis';

  export default {
    name: 'PurchasePriceDetail',
    components: {},
    data() {
      return {
        formData: {},
        treeProps: {
          label: 'stationName',
          children: 'stationTreeVOList',
          disabled: 'disabled',
        },
        treeNodeKey: 'stationId',
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    created() {
      const { query } = this.$route;
      Promise.all([
        cipherUnione.detail(query),
        unioneOrg.zdlDetail({ configId: `${query.id}` }),
      ]).then(([res, zdlRes]) => {
        res.modifiedTime = res.modifiedTime
          ? dayjs(res.modifiedTime).format('YYYY-MM-DD hh:mm:ss')
          : '——';
        this.formData = res;
        this.formData = {
          ...res,
          stationScopeType: zdlRes?.stationScopeType,
          dataIds: zdlRes?.dataIds || [],
          stationIds: [],
        };
        if (zdlRes?.stationScopeType === '2') {
          this.findOperatorStationTree();
        }
      });
    },
    methods: {
      // 获取机构下拉数据
      findOperatorStationTree() {
        // 按商户选择
        treeUtils.operatorStations({}).then(res => {
          const listData = res || [];
          const stationIds = listData
            .map(item => {
              item.stationName = item.operatorName;
              item.stationId = item.operatorId;
              item.stationTreeVOList = item.stationTreeVOList.filter(
                cd => this.formData.dataIds.indexOf(cd.stationId) !== -1
              );
              return item;
            })
            .filter(item => item.stationTreeVOList.length);
          this.formData.stationIds = stationIds;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    .box-card {
      width: 100%;
      height: auto;
      margin: 10px 0px 0px 0px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      .title {
      }
    }
  }
</style>
